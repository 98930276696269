import React from "react";
import theme from "theme";
import { Theme, Box, Text, Section, Icon, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdCheckCircle } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Higland Golf Kulübü
			</title>
			<meta name={"description"} content={"Olağanüstü bir golf deneyimi için ilk destinasyonunuz olan Highland Golf Kulübü'nü keşfedin. Güzel sahamızı, birinci sınıf tesislerimizi keşfedin ve golf tutkunlarından oluşan topluluğumuza katılın."} />
			<meta property={"og:title"} content={"Higland Golf Kulübü"} />
			<meta property={"og:description"} content={"Olağanüstü bir golf deneyimi için ilk destinasyonunuz olan Highland Golf Kulübü'nü keşfedin. Güzel sahamızı, birinci sınıf tesislerimizi keşfedin ve golf tutkunlarından oluşan topluluğumuza katılın."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
		</Helmet>
		<Components.Headernew />
		<Section md-padding="70px 0 80px 0" quarkly-title="Product-2" padding="60px 0 60px 0">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-3%20%281%29.jpg?v=2024-08-01T08:01:46.053Z) 0 0/cover no-repeat scroll padding-box"
					padding="100px 50px 100px 50px"
					md-margin="0px 0px 50px 0px"
					md-padding="50px 50px 50px 50px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					padding="0px 40px 0px 40px"
					lg-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 40px 0px" font="normal 500 42px/1.2 --fontFamily-serifGaramond" sm-margin="0px 0px 30px 0px">
						Her Golf Tutkunu için Kapsamlı Golf Hizmetleri
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 300 20px/1.5 --fontFamily-sans" color="#656b70">
						Golf Kulübü Green Horizons'ta oyunun ötesine geçerek olağanüstü bir golf deneyimi sunmayı hedefliyoruz. Kişiselleştirilmiş eğitimlerden birinci sınıf ekipmanlara kadar, hizmet yelpazemiz, ihtiyaçlarınıza en iyi şekilde yanıt vermek için tasarlanmıştır ve bizimle geçirdiğiniz zamanın eşsiz olmasını garanti eder. Oyununuzu geliştirmenize ve sahada daha fazla keyif almanıza nasıl yardımcı olabileceğimizi öğrenin.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						lg-align-self="center"
						md-align-self="auto"
						sm-flex-direction="column"
						sm-align-items="center"
						sm-align-self="center"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				width="100%"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
				flex-direction="column"
				flex-wrap="no-wrap"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline2" color="--light" margin="0px 0px 6px 0px">
							Kişiselleştirilmiş Eğitim ve Koçluk
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Golf Kulübü Green Horizons'ta her golfçünün benzersiz olduğunu anlıyoruz. Bu nedenle, özel ihtiyaçlarınıza uygun kişiselleştirilmiş eğitim ve koçluk hizmetleri sunuyoruz. Profesyonel eğitmenlerimiz, ister yeni başlayan ister deneyimli bir oyuncu olun, becerilerinizi geliştirmenize yardımcı olur. Kapsamlı eğitim programlarımız, temel vuruş mekaniğinden ileri stratejilere kadar her şeyi kapsar ve golf hedeflerinize ulaşmanızı sağlar.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline2" color="--light" margin="0px 0px 6px 0px">
							En Son Teknoloji Antrenman Alanları
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Kulübümüz, becerilerinizi geliştirmenize yardımcı olmak için en son teknoloji antrenman ekipmanlarına sahiptir. Golf sahamız, vuruşlarınızı izlemek ve anında geri bildirim sağlamak için son teknolojiyle donatılmıştır. Putting green ve kısa oyun alanlarımız ise hassasiyetinizi ve vuruş hissinizi geliştirmek için çeşitli zorluklar sunar. Bu olanaklar sayesinde, oyununuzun her yönünü geliştirebilir ve sürekli olarak kendinizi geliştirebilirsiniz.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline2" color="--light" margin="0px 0px 6px 0px">
							Olağanüstü Saha Bakımı
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Green Horizons'ta saha kalitesini en yüksek standartlarda tutmak en önemli önceliğimizdir. Özel saha bakım ekibimiz, sahamızın her zaman mükemmel durumda olmasını sağlamak için yorulmadan çalışır. İdeal şekilde bakılmış fairwaylerden bakımlı yeşilliklere kadar, her ayrıntıya özen gösterilir ve her ziyaretinizde en iyi oyun deneyimini yaşamanız sağlanır.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-1">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 60px 0px"
				font="normal 600 56px/1.2 --fontFamily-serifGaramond"
				width="50%"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				md-margin="0px 0px 40px 0px"
			>
				Green Horizons Deneyiminin Sunduğu Hizmetler
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/66ab354615ac54001870d61d/images/1-3.jpg?v=2024-08-01T08:01:46.057Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						srcSet="https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/1-3.jpg?v=2024-08-01T08%3A01%3A46.057Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/1-3.jpg?v=2024-08-01T08%3A01%3A46.057Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/1-3.jpg?v=2024-08-01T08%3A01%3A46.057Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/1-3.jpg?v=2024-08-01T08%3A01%3A46.057Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/1-3.jpg?v=2024-08-01T08%3A01%3A46.057Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/1-3.jpg?v=2024-08-01T08%3A01%3A46.057Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/1-3.jpg?v=2024-08-01T08%3A01%3A46.057Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 28px/1.2 --fontFamily-serifGaramond" text-align="center">
						Özel Eğitim Programları
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						text-align="left"
						font="--base"
						padding="0px 35px 0px 35px"
						lg-padding="0px 0 0px 0"
					>
						Özel Dersler: Profesyonel eğitmenlerimizle özel dersler.
						<br />
						<br />
						{"    "}Grup Dersleri: Diğerleriyle birlikte öğrenim ortamında buluşun.
						<br />
						<br />
						{"    "}Gençler için Programlar: Genç golfçüler için özel eğitim.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/66ab354615ac54001870d61d/images/1-1.jpg?v=2024-08-01T08:01:46.045Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						srcSet="https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/1-1.jpg?v=2024-08-01T08%3A01%3A46.045Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/1-1.jpg?v=2024-08-01T08%3A01%3A46.045Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/1-1.jpg?v=2024-08-01T08%3A01%3A46.045Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/1-1.jpg?v=2024-08-01T08%3A01%3A46.045Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/1-1.jpg?v=2024-08-01T08%3A01%3A46.045Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/1-1.jpg?v=2024-08-01T08%3A01%3A46.045Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ab354615ac54001870d61d/images/1-1.jpg?v=2024-08-01T08%3A01%3A46.045Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 28px/1.2 --fontFamily-serifGaramond" text-align="center">
						Gelişmiş Antrenman Alanları
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						text-align="left"
						font="--base"
						padding="0px 35px 0px 35px"
						lg-padding="0px 0 0px 0"
					>
						Driving Range: Vuruşlarınızı izleyin ve anında geri bildirim alın.{" "}
						<br />
						<br />
						{"\n    "}Vuruşlarınızın doğruluğunu ve gücünü geliştirin.
						<br />
						<br />
						{"    "}Kısa Oyun Alanı: Çip ve putting becerilerinizi geliştirin.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://images.unsplash.com/photo-1471253794676-0f039a6aae9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						srcSet="https://images.unsplash.com/photo-1471253794676-0f039a6aae9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1471253794676-0f039a6aae9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1471253794676-0f039a6aae9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1471253794676-0f039a6aae9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1471253794676-0f039a6aae9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1471253794676-0f039a6aae9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1471253794676-0f039a6aae9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 28px/1.2 --fontFamily-serifGaramond" text-align="center">
						Gurme Yemek Deneyimleri
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						text-align="left"
						font="--base"
						padding="0px 35px 0px 35px"
						lg-padding="0px 0 0px 0"
					>
						Restoran: Yetenekli şeflerimiz tarafından hazırlanan gurme yemekleri deneyimleyin.
						<br />
						<br />
						{"    "}Bar ve Lounge: Rahat ve zarif bir ortamda içeceğinizi yudumlayın.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Daha Fazla Bilgi İçin Bizimle İletişime Geçin
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="90%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					Sunduğumuz tam hizmet yelpazesi hakkında daha fazla bilgi edinmek için bizimle iletişime geçmenizi veya Green Horizons Golf Club'ı ziyaret etmenizi davet ediyoruz. Dost canlısı personelimiz, her zaman size yardımcı olmaya ve ilginizi çeken konularda ayrıntılı bilgi sağlamaya hazırdır. Bu sadece sunduklarımızın kısa bir özeti. Bugün bizimle iletişime geçerek tam teklif yelpazemizi keşfedin.
				</Text>
			</Box>
		</Section>
		<Components.Headernew />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});